import { useMediaQuery } from "@mui/material"
import { DataCollector, DataCollectorAction } from "@sog/sdk"
import { Typography } from "components/typography"
import { Link, navigate } from "gatsby"
import { LazyLoadImage } from "react-lazy-load-image-component"
import React from "react"
import { providerImage, rewardProgramImage } from "components/survey/utils"
import * as style from "./style.module.scss"

export namespace MapToDiv {
  type ProductCategory = "credit-cards" | "home-loans" | "personal-loans" | "car-loans" | "bank-accounts" | "savings-accounts" | "term-deposit-rates"
  type Feature = {
    img: string
    name: string
    urlSlug: string
  }
  type Reward = {
    id: string
    urlSlug: string
    nameFormatted: string
  }
  type Bank = {
    id: string
    urlSlug: string
    name: string
  }

  export const Features = (props: { features: (Feature | null)[][]; onClickAdditionalAction?: (feature: Feature) => void }) => {
    const isWide = useMediaQuery("(min-width:768px)")
    const { features, onClickAdditionalAction } = props

    const maxRowLength = features.reduce<number>((prevValue, currentValue) => (prevValue < currentValue.length ? currentValue.length : prevValue), 1)

    const featureCell = (feature: Feature | null, style: React.CSSProperties = {}) => {
      if (!feature) return null
      return (
        <Link
          className="category-link-cell-button"
          style={style}
          key={`page-cell-${feature.name}`}
          to={(feature.urlSlug[0] === "/" ? "" : "/") + feature.urlSlug}
          onClick={() => {
            onClickAdditionalAction && onClickAdditionalAction(feature)
            DataCollector.getInstance().addAction(DataCollectorAction.CONTENT_CATEGORY_SELECTED, { category: "/" + feature.urlSlug })
          }}
        >
          <div className="category-link-cell-button-image-container">
            <LazyLoadImage style={{width: "100%"}} src={feature.img} className="category-link-cell-button-image-container" />
          </div>
          <Typography.P comp="category-link-cell-button-text" style={{ maxWidth: "100%" }}>
            <div style={{ maxWidth: "100%", overflowX: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>{feature.name}</div>
          </Typography.P>
        </Link>
      )
    }

    const makeFeatureCellGrid = () => {
      return (
        <div className="category-link-cell-grid" style={{ marginTop: isWide ? "64px" : "50px", gridTemplateColumns: `repeat(${maxRowLength}, minmax(0,1fr))`, maxWidth: "none" }}>
          {features.map((row, rowIndex) => (
            <>{row.map((feature, colIndex) => featureCell(feature, { gridRow: 1 + rowIndex, gridColumn: 1 + colIndex }))}</>
          ))}
        </div>
      )
    }

    return <div style={isWide ? { marginBottom: "125px" } : { marginBottom: "60px" }}>{makeFeatureCellGrid()}</div>

    /*
    return (
      <div>
        {features.map((list, idx) => (
          <div key={idx} className={style.featureRow}>
            {list.map((feature, idx) => (
              <div className={style.featureCellContainer} key={idx}>
                {feature && (
                  <Link
                    to={"/" + feature.urlSlug}
                    className={style.featureCell}
                    onClick={() => {
                      onClickAdditionalAction && onClickAdditionalAction(feature)
                      DataCollector.getInstance().addAction(DataCollectorAction.CONTENT_CATEGORY_SELECTED, { category: "/" + feature.urlSlug })
                    }}
                  >
                    <div className={style.featureImageContainer}>
                      <LazyLoadImage src={feature.img} style={{height: "92%"}} alt="" />
                    </div>
                    <Typography.P comp="map-to-div-blue-medium">
                      {feature.name}
                    </Typography.P>
                  </Link>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
    )
    */
  }

  export const Banks = (props: { banks: (Bank | null)[][]; productCategory?: ProductCategory; onClickAdditionalAction?: (bank: Bank) => void }) => {
    const { banks, productCategory, onClickAdditionalAction } = props

    return (
      <div>
        {banks.map((list, idx) => (
          <div key={idx} className={style.bankRow}>
            {(list || []).map((bank, idx) => (
              <div className={style.bankCellContainer} key={idx}>
                {bank && (
                  <Link
                    to={"/" + bank.urlSlug + (productCategory ? "-" + productCategory : "")}
                    className={style.bankCell}
                    onClick={() => {
                      onClickAdditionalAction && onClickAdditionalAction(bank)
                      DataCollector.getInstance().addAction(DataCollectorAction.CONTENT_PROVIDER_SELECTED, { providerId: bank.id })
                    }}
                  >
                    <div className={style.bankImageContainer}>
                      <LazyLoadImage src={providerImage(bank.id)} style={{ maxHeight: "46px", height: "92%" }} alt="" />
                    </div>
                    <div style={{ marginTop: "15px" }} />
                    <Typography.P comp="map-to-div-grey">{bank.name}</Typography.P>
                  </Link>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
    )
  }

  export const Rewards = (props: { rewards: (Reward | null)[][]; onClickAdditionalAction?: (reward: Reward) => void }) => {
    const { rewards, onClickAdditionalAction } = props

    return (
      <div>
        {rewards.map((list, idx) => (
          <div key={idx} className={style.rewardRow}>
            {list.map((reward, idx) => (
              <div className={style.rewardCellContainer} key={idx}>
                {reward && (
                  <Link
                    to={"/" + reward.urlSlug}
                    className={style.rewardCell}
                    onClick={() => {
                      onClickAdditionalAction && onClickAdditionalAction(reward)
                      DataCollector.getInstance().addAction(DataCollectorAction.CONTENT_CATEGORY_SELECTED, { category: "/" + reward.urlSlug, rewardId: reward.id })
                    }}
                  >
                    <div className={style.rewardImageContainer}>
                      <LazyLoadImage src={rewardProgramImage(reward.id)} alt="" />
                    </div>
                    <div style={{ marginTop: "15px" }} />
                    <Typography.P comp="map-to-div-grey">{reward.nameFormatted}</Typography.P>
                  </Link>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
    )
  }
}
