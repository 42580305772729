import React from "react"
import { Link } from "gatsby"
import { Typography } from "components/typography"
import { DataCollector, DataCollectorAction } from "@sog/sdk"
import { ArrowForwardIos } from "@mui/icons-material"
import * as styles from "./style.module.scss"
import FactCheckedDialog from "components/content/fact-checked-dialog"

type LinkItem = {
  name: string
  to?: string
}
const ReturnLinkChain = (props: { style?: React.CSSProperties; linkChain?: LinkItem[]; currentLocationItem?: LinkItem; excludeBottomMargin?: boolean; includeFactCheckedDialog?: boolean; factCheckedDialogDateUpdated?: string; factCheckedDialogExcludeDateUpdatedFromButton?: boolean }) => {
  const linkChain = props.linkChain || []

  if (linkChain.length === 0 || (linkChain[0].to !== undefined && linkChain[0].to !== "/")) {
    linkChain.splice(0, 0, { name: "Home", to: "/" })
  }

  if (props.currentLocationItem && props.currentLocationItem.name && props.currentLocationItem.to !== linkChain[linkChain.length - 1].to) {
    const currentLocationItem = { name: props.currentLocationItem.name }
    linkChain.push(currentLocationItem)
  }

  const maxItems = 3
  const maxItemStringLength = 14

  const allowFactCheckedDialog = props.includeFactCheckedDialog && props.factCheckedDialogDateUpdated

  const Breadcrumbs = () => (
    <Typography.P comp={"breadcrumb-link"}>
      <div className={styles.containerScrollable} style={props.excludeBottomMargin || allowFactCheckedDialog ? { marginBottom: "0" } : undefined}>
        {linkChain.map((item: LinkItem, index: number) => {
          // if (index < linkChain.length - maxItems && index > 0) return null
          // if (index === linkChain.length - maxItems && index > 0) return (
          //   <span key={`return-link-${index}`} style={index === linkChain.length - 1 ? {overflow: "hidden", whiteSpace: "nowrap", textOverflow: 'ellipsis'} : undefined}>
          //     <span>...</span>
          //     {index < linkChain.length - 1 ? (
          //       <span style={{marginTop: "auto", marginBottom: "auto"}}>
          //         &nbsp;
          //         <ArrowForwardIos style={{fontSize: "7px"}} />
          //         &nbsp;
          //       </span>
          //     ) : null}
          //   </span>
          // )
          return (
            <span key={`return-link-${index}`} className={index === linkChain.length - 1 ? styles.lastItem : undefined}>
              {index < linkChain.length - 1 || !props.currentLocationItem || item.to ? (
                <Link
                  className={styles.link}
                  to={item.to ? item.to : "/"}
                  onClick={() => {
                    DataCollector.getInstance().addAction(DataCollectorAction.BROWSER_BACK, { breadcrumbs: true })
                  }}
                >
                  {item.name}
                </Link>
              ) : (
                <span className={styles.lastItemText}>{item.name}</span>
              )}
              {index < linkChain.length - 1 ? (
                <span style={{ margin: "auto 5px" }}>
                  <ArrowForwardIos style={{ fontSize: "7px" }} />
                </span>
              ) : null}
            </span>
          )
        })}
      </div>
    </Typography.P>
  )

  return (
    <div className={styles.outerContainer}>
      <Breadcrumbs />
      {allowFactCheckedDialog && <FactCheckedDialog dateString={props.factCheckedDialogDateUpdated as string} excludeDateStringFromButton={props.factCheckedDialogExcludeDateUpdatedFromButton} />}
    </div>
  )
}

export default ReturnLinkChain
