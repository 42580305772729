// extracted by mini-css-extract-plugin
export var getStartedContainer = "styles-module--get-started-container--0c54a";
export var getStartedHelpText = "styles-module--get-started-help-text--c7b20";
export var topSectionContainer = "styles-module--top-section-container--eb3e4";
export var topSectionContainerTablet = "styles-module--top-section-container-tablet--91de6";
export var topSectionImageContainerTablet = "styles-module--top-section-image-container-tablet--b5813";
export var topSectionImageContentContainer = "styles-module--top-section-image-content-container--b18d5";
export var topSectionImageStyleDesktop = "styles-module--top-section-image-style-desktop--11f41";
export var topSectionTextContainerTablet = "styles-module--top-section-text-container-tablet--97b43";
export var topSectionTextContainerTabletSecondary = "styles-module--top-section-text-container-tablet-secondary--802b6";
export var topSectionTextContentContainer = "styles-module--top-section-text-content-container--2ac26";