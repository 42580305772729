
export const chunkifyArray = (inputArray: any[], chunkSize: number) => {
  if (inputArray.length === 0) {
    return []
  }
  
  const resultArrays: any[][] = inputArray.reduce((resultArray, item, i) => {
    const chunkIndex = Math.floor(i / chunkSize)
    resultArray[chunkIndex] = (resultArray[chunkIndex] || []).concat([item])
    return resultArray
  }, [])

  if (chunkSize > resultArrays[resultArrays.length - 1].length) {
    const padding = Array(chunkSize - resultArrays[resultArrays.length - 1].length).fill(null)
    resultArrays[resultArrays.length - 1].push(...padding)
  }

  return resultArrays
}

export const getImagesFromMarkdown = (markdown: string) => {
  const images: {
    url: string,
    alt: string,
    title: string,
  }[] = []
  const matches = markdown.match(/\!\[.*\]\(.*\)/g)
  matches?.forEach((m) => {
    const alt = m.replace(/^\!\[/, "").replace(/\]\(.*\)$/, "")
    const urlPlusTitle = m.replace(/^\!\[.*\]\(/, "").replace(/\)$/, "")
    const url = urlPlusTitle.replace(/ \".*\"$/, "")
    const title = urlPlusTitle.replace(url, "").replace(/^ \"/, "").replace(/\"$/, "")
    images.push({
      url: url,
      alt: alt,
      title: title
    })
  })
  return images
}

export const getArticleUrlFromTitle = (articleTitle: string) => {
  return articleTitle
    .toLowerCase()
    .replace(/[^a-zA-Z0-9 ]/g, "")
    .replace(/ /g, "-")
}