import React from "react"
import { Typography } from "components/typography"
import * as styles from "./styles.module.scss"

export const SectionTextDivider = (props: { heading: string; text: string; style?: React.CSSProperties }) => {
  return (
    <div className={styles.sectionTextDivider} style={{ ...props.style }}>
      <Typography.H2 comp="external-landing-page-section-heading">{props.heading}</Typography.H2>
      <Typography.P comp="large">{props.text}</Typography.P>
    </div>
  )
}

export const SectionTextSubdivider = (props: { subheading: string; text?: string; style?: React.CSSProperties }) => {
  return (
    <div className={styles.sectionTextSubdivider} style={{ ...props.style }}>
      <Typography.H6 comp="provider-page-subheading">{props.subheading}</Typography.H6>
      {props.text && (
        <div style={{ marginTop: "10px" }}>
          <Typography.P comp="large">{props.text}</Typography.P>
        </div>
      )}
    </div>
  )
}
