// extracted by mini-css-extract-plugin
export var bankCell = "style-module--bank-cell--d06ec";
export var bankCellContainer = "style-module--bank-cell-container--f3dc7";
export var bankImageContainer = "style-module--bank-image-container--de4e8";
export var bankRow = "style-module--bank-row--d712e";
export var featureCell = "style-module--feature-cell--41436";
export var featureCellContainer = "style-module--feature-cell-container--76f9f";
export var featureImageContainer = "style-module--feature-image-container--28320";
export var featureRow = "style-module--feature-row--c7fb4";
export var rewardCell = "style-module--reward-cell--e9aeb";
export var rewardCellContainer = "style-module--reward-cell-container--0fa2f";
export var rewardImageContainer = "style-module--reward-image-container--75cb0";
export var rewardRow = "style-module--reward-row--33491";