import ExpandLess from "@mui/icons-material/ExpandLess"
import ExpandMore from "@mui/icons-material/ExpandMore"
import Collapse from "@mui/material/Collapse"
import React, { useState } from "react"
import * as style from "./style.module.scss"

export const CreditCardDisclaimer = (props: { isDisclaimerOpen?: boolean; isProvider?: boolean }) => {
  const isProvider = props.isProvider;
  const disclaimerContent = (
    <div>
      {isProvider ? <div>* Select “learn more” for additional information regarding fees, interest rates, rewards and other features.</div> : null}
      <div>^ Credit card reward programs can be difficult to compare due to tiered and capped points earn rate structures. To simplify comparison, we estimate the number of points you could earn per $1 spent, assuming $5,000 of spend on eligible purchases each month.</div>
    </div>
  )

  return <ContentDisclaimer disclaimer={disclaimerContent} isDisclaimerOpen={props.isDisclaimerOpen} />
}

export const HomeLoanDisclaimer = (props: { isDisclaimerOpen?: boolean; isProvider?: boolean }) => {
  const isProvider = props.isProvider;
  const disclaimerContent = (
    <div>
      <div>* Comparison rate is based on a secured loan of $150,000 over a 25 year term. <span className={style.boldText}>WARNING</span>: This comparison rate is true only for the examples given and may not include all fees and charges. Different terms, fees or other loan amounts might result in a different comparison rate.</div>
    </div>
  )

  return <ContentDisclaimer disclaimer={disclaimerContent} isDisclaimerOpen={props.isDisclaimerOpen} />
}

/**
 * Renders the disclaimer for Personal Loan products
 *
 * @param {{ isDisclaimerOpen?: boolean; isProvider?: boolean }} props The properties passed in for the component
 * @param {boolean} [props.isDisclaimerOpen] (Optional, default: false) whether the disclaimer is open or not
 * @param {boolean} [props.isProvider] (Optional, default: false) whether the disclaimer is being shown as part of the provider view or not
 * @return {JSX.Element} The rendered disclaimer component
 */
 export const PersonalLoanDisclaimer = (props: { isDisclaimerOpen?: boolean; isProvider?: boolean }) => {
  const isProvider = props.isProvider
  const disclaimerContent = (
    <div>
      <div>The comparison rates on this page are based on either a $30,000 loan over 5 years, or a $10,000 loan 3 years, as indicated with each rate. <span className={style.boldText}>WARNING</span>: This comparison rate applies only to the example given and may not include all fees and charges. Different terms, fees or loan amounts might result in different comparison rates.</div>
    </div>
  )

  return <ContentDisclaimer disclaimer={disclaimerContent} isDisclaimerOpen={props.isDisclaimerOpen} />
}

export const TermDepositDisclaimer = (props: { isDisclaimerOpen?: boolean; isProvider?: boolean }) => {
  const isProvider = props.isProvider;
  // TODO: add disclaimer
  const disclaimerContent = (
    <div>
      <div></div>
    </div>
  )

  return <ContentDisclaimer disclaimer={disclaimerContent} isDisclaimerOpen={props.isDisclaimerOpen} />
}

export const TransactionAccountDisclaimer = (props: { isDisclaimerOpen?: boolean; isProvider?: boolean; dateString?: string; wholeBalanceInterest?: boolean; includeBestDisclaimer?: boolean }) => {
  const isProvider = props.isProvider;

  const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
  const day = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
  const today = props.dateString ? new Date(props.dateString) : new Date()
  const todayString = `${day[today.getDay()]}, ${today.getDate()} ${month[today.getMonth()]} ${today.getFullYear()}`

  today.getDay()
  // TODO: add disclaimer
  const disclaimerContent = (
    <div>
      {props.includeBestDisclaimer && <><div>
        &sup1; Everyday Bank Accounts can be difficult to compare due to varied interest rate structures (e.g. tiered vs stepped balance thresholds for earning interest), along with differing fee waiver criteria (e.g. based upon customer segment, account balance and/or account spend). To simplify comparison, we rank the ‘best’ products assuming a $5,000 account balance, monthly spend of $2,000 (of which 5% is with overseas merchants that may result in an FX fee), and a monthly account deposit of $2,000. From here we rank products considering monthly account fees, fee waivers (where applicable), interest earned on account balances and FX fees. Select “learn more” for additional information regarding fees, interest rates, FX and other features.
      </div>
      <br/></>}
      <div>
        {isProvider
          ? "^ Different interest rates may apply to different account balances. The interest rate displayed for each product is the minimum rate applicable. Higher interest rates may apply when certain conditions are met. Select “learn more” for additional information regarding fees, interest rates, FX and other features. Rates are subject to change."
          : "^ Different interest rates may apply to different account balances. The interest rate displayed is the minimum rate applicable. Higher interest rates may apply when certain conditions are met. Rates are subject to change."
        }
      </div>
      <br/>
      <div>
        * A foreign currency (FX) fee may be charged on international transactions such as those made while travelling overseas or when shopping online with a overseas merchant.
        <br/>
        While the fee is usually charged as a % of the transaction value, in some circumstances such as small value transactions there may be a minimum flat fee applied (eg. $5).
      </div>
    </div>
  )

  return <ContentDisclaimer disclaimer={disclaimerContent} isDisclaimerOpen={props.isDisclaimerOpen} />
}

export const SavingsAccountDisclaimer = (props: { isDisclaimerOpen?: boolean; isProvider?: boolean; dateString?: string; wholeBalanceInterest?: boolean; includeBestDisclaimer?: boolean }) => {
  const isProvider = props.isProvider;

  const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
  const day = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
  const today = props.dateString ? new Date(props.dateString) : new Date()
  const todayString = `${day[today.getDay()]}, ${today.getDate()} ${month[today.getMonth()]} ${today.getFullYear()}`

  today.getDay()
  // TODO: add disclaimer
  const disclaimerContent = (
    <div>
      <div>
        Terms & Conditions, eligibility critera, fees and charges may apply.
      </div>
      <br/>
      <div>
        * The maximum variable interest rate is inclusive of any applicable introductory or bonus rates which may apply.
      </div>
    </div>
  )

  return <ContentDisclaimer disclaimer={disclaimerContent} isDisclaimerOpen={props.isDisclaimerOpen} />
}

const ContentDisclaimer = (props: { disclaimer: JSX.Element; isDisclaimerOpen?: boolean }) => {
  const [open, setOpen] = useState(props.isDisclaimerOpen || false)

  return (
    <div>
      <div
        className={style.disclaimer}
        onClick={() => {
          setOpen(prevVal => !prevVal)
        }}
        role="button"
        tabIndex={0}
      >
        <div style={{ textAlign: "left", height: "18px", display: "block", lineHeight: "18px", fontSize: "16px", fontFamily: "Arboria-Bold, sans-serif" }}>More information</div>
        {open ? <ExpandLess /> : <ExpandMore />}
      </div>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <div style={{ textAlign: "left", fontFamily: "arboria-book", color: "#797979", letterSpacing: "0px", fontSize: "16px" }}>
          {props.disclaimer}
        </div>
      </Collapse>
    </div>
  )
}

  export default ContentDisclaimer
