import { LazyLoadImage } from "react-lazy-load-image-component";
import * as style from "./style.module.scss"
import React from "react"
import { providerImage } from "components/survey/utils";

export const ListHeaderProvider = (props: {
    children?: React.ReactNode;
    isMobile?: boolean
}) => {
    return (
        <div
          className={props.isMobile ? style.listHeaderProviderMobile : style.listHeaderProvider}
        >
            <div style={{
                marginLeft: "20px",
                color: "white",
                fontFamily: "Arboria-Bold, sans-serif",
                fontWeight: 700,
                lineHeight: "40px",
                fontSize: "20px"
            }}>{props.children}</div>
        </div>
    )
}

export const ListHeaderProduct = (props: {
    children?: React.ReactNode;
    style?: React.CSSProperties | undefined
}) => {
    return (
        <div className={style.listHeaderProduct} style={props.style}>
            <div style={{
                marginLeft: "20px",
                color: "white",
                fontFamily: "Arboria-Bold, sans-serif",
                fontWeight: 700,
                lineHeight: "40px",
                fontSize: "22px"
            }}>{props.children}</div>
        </div>
    )
}

export const ListHeaderProviderPage = (props: {
    provider: any
    children?: React.ReactNode;
    isMobile?: boolean
}) => {
    return (
        <div className={props.isMobile ? style.listHeaderProviderPageMobile : style.listHeaderProviderPage}>
            <div style={{
                color: "#202020",
                fontFamily: "Arboria-Bold, sans-serif",
                fontWeight: 700,
                lineHeight: "40px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
            }}>
                {props.children}
            </div>
            <LazyLoadImage src={providerImage(props.provider.id)} style={{height: "70%"}} alt={`${props.provider.name} logo`} />
        </div>
    )
}