import React from "react"
import * as styles from "./style.module.scss"
import { Verified, Close } from "@mui/icons-material"
import { Typography } from "components/typography"
import { Dialog, IconButton, useMediaQuery } from "@mui/material"
import { LazyLoadImage } from "react-lazy-load-image-component"

const FactCheckedDialog = ({ dateString, excludeDateStringFromButton }: { dateString: string; excludeDateStringFromButton?: boolean }) => {
  const isWide = useMediaQuery("(min-width:768px)")

  const [open, setOpen] = React.useState(false)
  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const factCheckedDialog = () => {
    return (
      <Dialog
        onClose={() => {
          handleClose()
        }}
        aria-labelledby="customized-dialog-title"
        open={open}
        PaperProps={{
          square: true,
          style: {
            border: "3px solid #5BBA6F",
            maxWidth: isWide ? "720px" : "360px",
          },
        }}
      >
        <div className={styles.verifiedDisclaimerExitButtonContainer}>
          <IconButton
            aria-label="close"
            style={{ float: "right", color: "#7d7d7d" }}
            onClick={() => {
              handleClose()
            }}
            size="large"
          >
            <Close style={{ fontSize: "24px", color: "#00AFC5" }} />
          </IconButton>
        </div>
        <div className={styles.verifiedDisclaimerContentContainer}>
          <LazyLoadImage src="logo_mini.png" style={{width: "50px"}} />
          <Typography.H3 comp="provider-overview-verified-disclaimer-title">Why trust us?</Typography.H3>
          <div className={styles.verifiedDisclaimerKeyFactsGrid}>
            <div className={styles.verifiedDisclaimerKeyFactsGridItem}>
              <LazyLoadImage src="images/building_columns.png" style={{ height: "25px", marginBottom: isWide ? "20px" : "10px" }} />
              <div className={styles.keyFactNumber}>62</div>
              <div className={styles.keyFactText}>Leading and small banks analyzed</div>
            </div>
            <div className={styles.verifiedDisclaimerKeyFactsGridItem}>
              <LazyLoadImage src="images/checklist.png" style={{ height: "25px", marginBottom: isWide ? "20px" : "10px" }} />
              <div className={styles.keyFactNumber}>{">"}300</div>
              <div className={styles.keyFactText}>Products and offers ranked</div>
            </div>
            <div className={styles.verifiedDisclaimerKeyFactsGridItem}>
              <LazyLoadImage src="images/checklist.png" style={{ height: "25px", marginBottom: isWide ? "20px" : "10px" }} />
              <div className={styles.keyFactNumber}>28</div>
              <div className={styles.keyFactText}>Criteria used to evaluate providers</div>
            </div>
          </div>
          <Typography.P>At Stay or Go, we prioritise accuracy, currency, and trustworthiness when it comes to the information on our website. We proudly uphold our editorial principles to ensure fairness, precision, and reliability in the content we provide. Rest assured, you can rely on us to make informed financial decisions with confidence.</Typography.P>
          <br />
          <Typography.P>Our primary objective is clear: to simplify your search and alleviate the stress of finding the optimal financial products. Each day, we dedicate ourselves to providing you with peace-of-mind, assisting you in achieving your financial objectives with confidence.</Typography.P>
          <br />
          <Typography.P>
            Products, offers and other information on this page was last updated on <strong>{dateString}</strong>.
          </Typography.P>
        </div>
      </Dialog>
    )
  }

  return (
    <>
      <div className={styles.verifiedDisclaimerLinkContainer} onClick={() => handleClickOpen()}>
        <Verified style={{ fontSize: "15px", lineHeight: "17px", marginRight: "6px", color: "#5BBA6F" }} />
        <div>Fact checked and verified{!excludeDateStringFromButton && `. Updated ${dateString}`}</div>
      </div>
      {factCheckedDialog()}
    </>
  )
}

export default FactCheckedDialog
