import React from "react"
import useMediaQuery from "@mui/material/useMediaQuery"
import { Typography } from "components/typography"
import * as Clickable from "components/clickable"
import ReturnLinkChain from "components/content/return-link-chain"
import * as styles from "./styles.module.scss"
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos"
import { Link } from "gatsby"
import FactCheckedDialog from "components/content/fact-checked-dialog"

const ProductCategoryPageTopSection = (props: { productCategory: "cc" | "hl" | "pl" | "cl" | "td" | "tx" | "sv"; dateString: string; pageHeading: string; categoryDescription: string; categoryDescriptionHeading?: string; imageOrVideoComponent?: JSX.Element; surveyUrlSlug?: string; surveyButtonOnClick?: () => any }) => {
  const isWide = useMediaQuery("(min-width:768px)")

  let categoryName = "Credit Cards"
  switch (props.productCategory) {
    case "cc":
      categoryName = "Credit Cards"
      break
    case "hl":
      categoryName = "Home Loans"
      break
    case "pl":
      categoryName = "Personal Loans"
      break
    case "cl":
      categoryName = "Car Loans"
      break
    case "td":
      categoryName = "Term Deposits"
      break
    case "tx":
      categoryName = "Bank Accounts"
      break
    case "sv":
      categoryName = "Savings Accounts"
      break
  }

  return (
    <div style={{ marginTop: isWide ? "60px" : "30px" }}>
      <ReturnLinkChain linkChain={[]} currentLocationItem={{ name: categoryName }} includeFactCheckedDialog={isWide} factCheckedDialogDateUpdated={props.dateString} excludeBottomMargin={isWide} />
      <Typography.H2 comp="provider-page-title">{props.pageHeading}</Typography.H2>
      <div style={isWide ? undefined : { marginBottom: "45px" }}>
        <div className={styles.topSectionContainer}>
          <div className={styles.topSectionTextContentContainer} style={{ width: props.imageOrVideoComponent ? undefined : "100%", textAlign: props.categoryDescriptionHeading ? undefined : "left" }}>
            {props.categoryDescriptionHeading && <Typography.H3 comp="article">{props.categoryDescriptionHeading}</Typography.H3>}
            <Typography.P comp="large">{props.categoryDescription}</Typography.P>
            {props.surveyUrlSlug && (
              <div className={styles.getStartedContainer}>
                <Clickable.Text
                  comp={1}
                  component={Link}
                  to={props.surveyUrlSlug}
                  className="landing-page-button"
                  style={{ width: "100%" }}
                  onClick={() => {
                    props.surveyButtonOnClick && props.surveyButtonOnClick()
                  }}
                >
                  <div style={{ margin: "auto" }}>
                    Get Started <ArrowForwardIos style={{ fontSize: "50%" }} />
                  </div>
                </Clickable.Text>
                <Typography.P comp="small">
                  <div className={styles.getStartedHelpText}>It's easy, and takes just a few minutes!</div>
                </Typography.P>
              </div>
            )}
          </div>
          {props.imageOrVideoComponent && <div className={styles.topSectionImageContentContainer}>{props.imageOrVideoComponent}</div>}
        </div>
      </div>
      {!isWide && props.dateString && <FactCheckedDialog dateString={props.dateString} />}
    </div>
  )
}

export default ProductCategoryPageTopSection
